import React from 'react';
import Implantologia from '../../components/Uslugi/Implantologia';
import Layout from '../../layout/pl';

const ImplantologiaComp = (props) => {
	return (
		<Layout {...props}>
			<Implantologia />
		</Layout>
	);
};

export default ImplantologiaComp;
