import React from 'react';
import '../../styles/Implantologia.scss';
import TitleHeader from '../TitleHeader';
import { FormattedMessage } from 'react-intl';

const Implantologia = () => {
	return (
		<div>
			<TitleHeader title={<FormattedMessage id="menu.services.implantology" />} />
			<div className="grid-two-column content">
				<FormattedMessage className="first-column" id="services.implantology.safeCorrection" tagName="h2" />
				<FormattedMessage id="services.implantology.safeCorrection.description" tagName="p" />

				<FormattedMessage className="first-column" id="services.implantology.benefitsForYou" tagName="h2" />
				<FormattedMessage id="services.implantology.benefitsForYou.description" tagName="p" />

				<FormattedMessage className="first-column" id="services.implantology.recommendations" tagName="h2" />
				{/* <h2 className="first-column">zalecenia</h2> */}
				{/* <p className="second-column"> */}
				<table id="tabelka-zalecen" className="tabelka">
					<thead>
						<tr>
							<td colSpan="2">
								<FormattedMessage id="services.implantology.recommendations.head" tagName="h3" />
								{/* <h3>Dla powodzenia zabiegu implantacji/odbudowy kości koniecznie powinieneś:</h3> */}
							</td>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<img
									src="http://sztukastomatologii.pl/wp-content/uploads/2019/03/bandage.png"
									alt="bandaż"
								/>
							</td>
							<FormattedMessage id="services.implantology.recommendations.td1" tagName="td" />
							{/* <td>Usunąć z ust sterylny gazik dopiero 30 min po zabiegu</td> */}
						</tr>
						<tr>
							<td>
								<img
									src="http://sztukastomatologii.pl/wp-content/uploads/2019/03/medicine.png"
									alt="leki"
								/>
							</td>
							<FormattedMessage id="services.implantology.recommendations.td2" tagName="td" />
							{/* <td>Przyjmować leki zgodnie z zaleceniami</td> */}
						</tr>
						<tr>
							<td>
								<img
									src="http://sztukastomatologii.pl/wp-content/uploads/2019/03/toothbrush.png"
									alt="szczotkować"
								/>
							</td>
							<FormattedMessage id="services.implantology.recommendations.td3" tagName="td" />
							{/* <td>
								Dbać o higienę jamy ustnej używając bardzo miękkiej szczoteczki oraz płynu
								antyseptycznego
							</td> */}
						</tr>
						<tr>
							<td>
								<img
									src="http://sztukastomatologii.pl/wp-content/uploads/2019/03/contact.png"
									alt="skontaktować się"
								/>
							</td>
							<FormattedMessage id="services.implantology.recommendations.td4" tagName="td" />
							{/* <td>Skontaktować się z nami gdy coś Cię niepokoi</td> */}
						</tr>
						<tr>
							<td>
								<img
									src="http://sztukastomatologii.pl/wp-content/uploads/2019/03/cold.png"
									alt="okłady"
								/>
							</td>
							<FormattedMessage id="services.implantology.recommendations.td5" tagName="td" />
							{/* <td>Stosować chłodne okłady na operowaną okolicę</td> */}
						</tr>
						<tr>
							<td>
								<img
									src="http://sztukastomatologii.pl/wp-content/uploads/2019/03/cold-drink.png"
									alt="zimne napoje"
								/>
							</td>
							<FormattedMessage id="services.implantology.recommendations.td6" tagName="td" />
							{/* <td>Pić tylko chłodne niegazowane napoje przez 48h po zabiegu</td> */}
						</tr>
						<tr>
							<td>
								<img
									src="http://sztukastomatologii.pl/wp-content/uploads/2019/03/cold-eat.png"
									alt="zimne jedzenie"
								/>
							</td>
							<FormattedMessage id="services.implantology.recommendations.td7" tagName="td" />
							{/* <td>
								Jeść tylko chłodne, miękkie, półpłynne lekko doprawione potrawy przez 48 h po zabiegu
							</td> */}
						</tr>
						<tr>
							<td>
								<img
									src="http://sztukastomatologii.pl/wp-content/uploads/2019/03/check.png"
									alt="wizyta kontrolna"
								/>
							</td>
							<FormattedMessage id="services.implantology.recommendations.td8" tagName="td" />
							{/* <td>Przyjść na wizytę kontrolną</td> */}
						</tr>
					</tbody>
					<thead>
						<tr>
							<td colSpan="2">
								{/* <h3>Dla właściwego gojenia po zabiegu implantacji/odbudowy kości nie wolno:</h3> */}
								<FormattedMessage id="services.implantology.recommendations.head2" tagName="h3" />
							</td>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<img
									src="http://sztukastomatologii.pl/wp-content/uploads/2019/03/dont-hot-drink.png"
									alt="ciepłe napoje"
								/>
							</td>
							<FormattedMessage id="services.implantology.recommendations.td9" tagName="td" />
							{/* <td>Pić gorących, gazowanych napojów przez 48 h</td> */}
						</tr>
						<tr>
							<td>
								<img
									src="http://sztukastomatologii.pl/wp-content/uploads/2019/03/dont-eat.png"
									alt="nie jedz"
								/>
							</td>
							<FormattedMessage id="services.implantology.recommendations.td10" tagName="td" />
							{/* <td>Jeść przez 2 h po zabiegu</td> */}
						</tr>
						<tr>
							<td>
								<img
									src="http://sztukastomatologii.pl/wp-content/uploads/2019/03/dont-smoke.png"
									alt="nie pal"
								/>
							</td>
							<FormattedMessage id="services.implantology.recommendations.td11" tagName="td" />
							{/* <td>Palić tytoniu, papierosów elektronicznych przez 48 h po zabiegu</td> */}
						</tr>
						<tr>
							<td>
								<img
									src="http://sztukastomatologii.pl/wp-content/uploads/2019/03/dont-spicy.png"
									alt="nie jedz ostrego"
								/>
							</td>
							<FormattedMessage id="services.implantology.recommendations.td12" tagName="td" />
							{/* <td>
								Jeść gorących, mocno przyprawionych potraw oraz takich które wymagają mocnego gryzienia
								oraz przeżuwania przez 48 h po zabiegu
							</td> */}
						</tr>
						<tr>
							<td>
								<img
									src="http://sztukastomatologii.pl/wp-content/uploads/2019/03/dont-drink.png"
									alt="nie pij alkoholu"
								/>
							</td>
							<FormattedMessage id="services.implantology.recommendations.td13" tagName="td" />
							{/* <td>Pić alkoholu przez 7 dni po zabiegu</td> */}
						</tr>
						<tr>
							<td>
								<img
									src="http://sztukastomatologii.pl/wp-content/uploads/2019/03/dont-gym.png"
									alt="nie ćwicz"
								/>
							</td>
							<FormattedMessage id="services.implantology.recommendations.td14" tagName="td" />
							{/* <td>Ograniczyć wysiłek fizyczny do minimum przez 7 dni</td> */}
						</tr>
						<tr>
							<td>
								<img
									src="http://sztukastomatologii.pl/wp-content/uploads/2019/03/dont-spa.png"
									alt="nie korzystaj z sauny"
								/>
							</td>
							<FormattedMessage id="services.implantology.recommendations.td15" tagName="td" />
							{/* <td>Nie korzystać z sauny, solarium przez 7 dni</td> */}
						</tr>
						<tr>
							<td>
								<img
									src="http://sztukastomatologii.pl/wp-content/uploads/2019/03/dont-tooth.png"
									alt="nie dotykaj językiem"
								/>
							</td>
							<FormattedMessage id="services.implantology.recommendations.td16" tagName="td" />
							{/* <td>Nie „sprawdzać” okolicy zabiegu językiem lub odchylając wargę, policzek</td> */}
						</tr>
						<tr>
							<td>
								<img
									src="http://sztukastomatologii.pl/wp-content/uploads/2019/03/dont-pulse.png"
									alt="unikaj aktywności"
								/>
							</td>
							<FormattedMessage id="services.implantology.recommendations.td17" tagName="td" />
							{/* <td>
								W przypadku zabiegu podniesienia dna zatoki szczękowej przez 7 dni unikać aktywności
								które wiążą się ze zmianą ciśnienia np. lotu samolotem, nurkowania, wycieczek górskich.
								Nie oczyszczać nosa poprzez wydmuchiwanie, zrezygnować z grania na instrumentach dętych
								np. flet, klarnet, trąbka, puzon.
							</td> */}
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
};
export default Implantologia;
